import clsx from 'clsx'
import Link from 'next/link'
import routes from '../../../lib/routes'
import Cart from './Cart'

export type CartLinkProps = { quantity?: number; className?: string }

export default function CartLink({ quantity, className }: CartLinkProps) {
  return (
    <Link
      href={routes.cart()}
      prefetch={false}
      aria-label="Cart"
      className={clsx(
        'flex flex-row items-center space-x-3 rounded text-ds-neutral focus:outline-none focus:ring-2 focus:ring-ds-yellow',
        className
      )}
    >
      <Cart quantity={quantity} />
    </Link>
  )
}
