import CartIcon from '../../../public/icon/cart.svg'

export default function Cart({ quantity }: { quantity?: number }) {
  return (
    <span className="relative block">
      <CartIcon className="w-[1.375rem] text-ds-neutral" />
      {quantity ? (
        <span className="absolute -top-2 -right-2 flex h-4 w-4 items-center justify-center rounded-full bg-ds-purple-100 text-ds-purple-800 text-xs leading-none">
          {quantity}
        </span>
      ) : null}
    </span>
  )
}
