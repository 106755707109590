export function NewChevronRightIcon({ className = '' }: { className?: string }) {
  return (
    <svg className={className} viewBox="0 0 87 86" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M36.7736 59.4525C35.9729 59.4515 35.2022 59.1478 34.6161 58.6022C34.306 58.2975 34.0597 57.934 33.8916 57.5331C33.7235 57.1322 33.637 56.7018 33.637 56.267C33.637 55.8323 33.7235 55.4019 33.8916 55.0009C34.0597 54.6 34.306 54.2366 34.6161 53.9318L44.8389 43.728C45.0753 43.4902 45.208 43.1685 45.208 42.8332C45.208 42.4979 45.0753 42.1763 44.8389 41.9385L34.5336 31.6267C33.9556 31.0283 33.6358 30.2269 33.643 29.395C33.6503 28.5631 33.9839 27.7673 34.5722 27.179C35.1605 26.5907 35.9563 26.257 36.7882 26.2498C37.6201 26.2426 38.4216 26.5624 39.02 27.1403L52.4664 40.6122C53.0613 41.2072 53.3954 42.0141 53.3954 42.8554C53.3954 43.6967 53.0613 44.5036 52.4664 45.0986L39.02 58.5261C38.7248 58.8205 38.3744 59.0539 37.989 59.2129C37.6035 59.3719 37.1905 59.4533 36.7736 59.4525Z"
        fill="#681E61"
      />
    </svg>
  )
}
