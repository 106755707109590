import type { UrlObject } from 'url'
import Link from 'next/link'
import { useRouter } from 'next/router'
import COMPANY_INFO from '../../../../lib/constants/COMPANY_INFO'
import { parseAsPath } from '../../../../lib/utils/parseAsPath'
import { NewChevronRightIcon } from '../../legacy/icons/NewChevronRightIcon'

export type MainLayoutBreadcrumb = { label: React.ReactNode; path: string | UrlObject }

export default function MainLayoutBreadcrumbs({ crumbs }: { crumbs: MainLayoutBreadcrumb[] }) {
  const router = useRouter()

  return (
    <div className="bleed hidden bg-eggshell px-4 print:hidden md:block">
      <div className="container">
        <ul
          className="flex flex-row items-center space-x-4 py-3 text-lg font-bold text-pebble"
          itemScope
          itemType="http://schema.org/BreadcrumbList"
        >
          {crumbs.map((crumb, index) => {
            const isActive = parseAsPath(router.asPath).pathname === crumb.path

            return (
              <li
                key={JSON.stringify(crumb.path)}
                itemProp="itemListElement"
                itemScope
                itemType="http://schema.org/ListItem"
                className="flex flex-row items-center"
              >
                {index !== 0 && (
                  <div className="mr-4 h-6 w-6">
                    <NewChevronRightIcon />
                  </div>
                )}
                <Link href={crumb.path} prefetch={false}>
                  {isActive ? (
                    <>
                      <meta
                        itemProp="item"
                        content={`${COMPANY_INFO.storefrontUrl}${crumb.path}`}
                      />
                      <span itemProp="name">{crumb.label}</span>
                    </>
                  ) : (
                    <span className="hover:text-gray-500">
                      <meta
                        itemProp="item"
                        content={`${COMPANY_INFO.storefrontUrl}${crumb.path}`}
                      />
                      <span itemProp="name">{crumb.label}</span>
                    </span>
                  )}
                </Link>
                <meta itemProp="position" content={(index + 1).toString()} />
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )
}
